import { useMsal } from '@azure/msal-react';
import { Alert, Button, Layout } from 'antd';
import { AuthFooter } from 'components/auth/footer';
import { GetStaticProps, NextPage } from 'next';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingPage } from '../components/loading.page';
import { useStatusQuery } from '../graphql/query/status.query';
import { NextConfigType } from '../next.config.type';

const { publicRuntimeConfig } = getConfig() as NextConfigType;

const Index: NextPage = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const [loading, setLoading] = useState<boolean>(
    publicRuntimeConfig.spa ||
      (process.browser && router.pathname !== window.location.pathname)
  );
  const status = useStatusQuery();

  const fetchData = async () => {
    await instance.loginRedirect();
  };

  useEffect(() => {
    if (router.pathname !== window.location.pathname) {
      let href = router.asPath;
      const as = router.asPath;
      const possible = [
        /(\/form\/)[^/]+/i,
        /(\/admin\/forms\/)[^/]+/i,
        /(\/admin\/users\/)[^/]+/i,
      ];

      possible.forEach((r) => {
        if (r.test(as)) {
          href = href.replace(r, '$1[id]');
        }
      });

      router.replace(href, as).catch((e: Error) => {
        console.error('failed redirect', e);
      });
    }
  });

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 10000);
    }
  }, [loading]);

  if (loading) {
    return <LoadingPage message={t('loading')} />;
  }

  return (
    <Layout
      style={{
        height: '100vh',
        width: '100vw',
        background: publicRuntimeConfig.mainBackground,
      }}
    >
      <div
        style={{
          margin: 'auto',
          maxWidth: '100%',
          width: 500,
          textAlign: 'center',
        }}
      >
        <img
          alt={'DeloitteDigital'}
          style={{ maxWidth: '80%' }}
          src={require('../assets/images/logo_white.png')}
        />
        <Button
          size='large'
          type='primary'
          htmlType='button'
          onClick={async () => fetchData()}
          block
          style={{ marginTop: 40, maxWidth: '80%' }}
        >
          Log in with SSO
        </Button>
      </div>

      {status.error && (
        <Alert
          message={`There is an error with your API connection: ${status.error.message}`}
          style={{ marginBottom: 40, marginLeft: 16, marginRight: 16 }}
        />
      )}
      {/* <AuthFooter /> */}
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  // just to be conforming with eslint
  await Promise.resolve();

  return {
    revalidate: 10,
    props: {},
  };
};

export default Index;
