import { useMsal } from '@azure/msal-react';
import { Button, Spin } from 'antd';
import React from 'react';

interface Props {
  message?: string;
}

export const LoadingPage: React.FC<Props> = (props) => {
  const { instance } = useMsal();
  return (
    <div
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Spin size='large' />
      {props.message}
      <Button
        size='large'
        type='primary'
        htmlType='button'
        onClick={async () => {
          await instance.loginRedirect();
        }}
        block
        style={{ marginTop: 40, maxWidth: '25%' }}
      >
        Log in with SSO
      </Button>
    </div>
  );
};
